import * as React from 'react'
import { lightBlue, cyan } from 'shared/colors'
import styled from 'styled-components'
import BidAccepted from '../icons/bid-accepted-dark.svg'
import Notes from '../icons/notes-dark.svg'
import Trophy from '../icons/trophy-dark.svg'
import 'react-vis/dist/style.css'

import {
	XYPlot,
	HorizontalGridLines,
	VerticalBarSeries,
	GradientDefs,
	makeWidthFlexible,
	LabelSeries,
	ChartLabel,
} from 'react-vis'
import { laptop, tablet, mobile } from 'shared/media-queries'

const Container = styled.div`
	height: 941px;
	background-image: radial-gradient(circle at 31% -48%, #32ade6, #1d355e 55%);
	${mobile} {
		height: auto;
	}
`

const Spacer = styled.div`
	height: 50px;
	${mobile} {
		display: none;
	}
`

const Content = styled.div`
	margin: 0 auto;
	justify-content: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	${mobile} {
		padding-top: 75px;
		padding-bottom: 50px;
	}
`

const Title = styled.div`
	padding-bottom: 20px;
	padding-top: 75px;
	font-size: 38px;
	font-weight: bold;
	color: white;
	text-align: center;
	${tablet} {
		width: 369px;
		margin: 0 auto;
	}
	${mobile} {
		padding-top: 25px;
		width: 311px;
		font-size: 32px;
		padding-bottom: 40px;
	}
`

const StatsContainer = styled.div`
	display: flex;
	${mobile} {
		flex-direction: column;
		align-items: center;
	}
`

const Text = styled.div`
	margin-left: 30px;
	font-size: 22px;
	font-weight: 300;
	line-height: 1.33;
	color: white;
	${tablet} {
		font-size: 18px;
	}
`

const Item = styled.div`
	display: flex;
	align-items: center;
	padding: 0 80px;
	${laptop} {
		padding: 0 42px;
	}
	${tablet} {
		padding: 0 33px;
	}
	${mobile} {
		padding: 0;
		height: 129px;
	}
`

const BottomLabelContainer = styled.div`
	display: flex;
	margin-left: 40px;
	margin-top: -30px;
	justify-content: space-around;
	text-align: center;
	${tablet} {
	}
	${mobile} {
		margin-left: 35px;
	}
`

const Label = styled.div`
	font-size: 22px;
	color: white;
	flex: 1 1 30%;
	${mobile} {
		font-size: 14px;
	}
`

const GradientText = styled.div`
	font-size: 68px;
	font-weight: bold;
	color: ${cyan};
	@supports (-webkit-background-clip: text) {
		background: linear-gradient(217deg, ${cyan}, ${lightBlue});
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
	${tablet} {
		font-size: 54px;
	}
`

const WideBar = styled.div`
	width: 1210px;
	height: 1px;
	border: solid 1px rgba(255, 255, 255, 0.18);
	margin: 0 auto;
	${laptop} {
		width: 820px;
	}
	${tablet} {
		width: 644px;
	}
	${mobile} {
		width: 324px;
	}
`

const LowerHalf = styled.div`
	margin-top: 50px;
`

const Divider = styled.div`
	width: 2px;
	height: 167px;
	border: solid 1px rgba(255, 255, 255, 0.18);
	${tablet} {
		height: 110px;
	}
	${mobile} {
		width: 324px;
		height: 1px;
	}
`

const ChartContainer = styled.div`
	width: 1200px;
	margin: 0 auto;
	${laptop} {
		width: 875px;
	}
	${tablet} {
		width: 675px;
	}
	${mobile} {
		width: 100%;
	}
`

const AcceptedSVGWrapper = styled.div`
	width: 76px;
	height: 96px;
	${tablet} {
		width: 58px;
		height: 73px;
	}
	${mobile} {
		width: 64px;
		height: 80px;
	}
`

const NotesSVGWrapper = styled.div`
	width: 97px;
	height: 96px;
	${tablet} {
		width: 73px;
		height: 73px;
	}
	${mobile} {
		width: 76px;
		height: 75px;
	}
`

const TrophySVGWrapper = styled.div`
	width: 73px;
	height: 114px;
	${tablet} {
		width: 64px;
		height: 94px;
	}
	${mobile} {
		width: 63px;
		height: 93px;
	}
`

const gradient = (
	<GradientDefs>
		<linearGradient id="chill" gradientTransform="rotate(90)">
			<stop offset="15%" stopColor={cyan} stopOpacity={0.9} />
			<stop offset="85%" stopColor={lightBlue} stopOpacity={0.9} />
		</linearGradient>
	</GradientDefs>
)

const FlexibleXYPlot = makeWidthFlexible(XYPlot)
const FlexibleChart = styled(FlexibleXYPlot)`
	.rv-xy-plot__axis__title text {
		font-size: 14px;
		font-weight: 600;
		fill: white;
		text-anchor: middle;
		letter-spacing: 1px;
		transform: rotate(-90deg) translate(-10px, 30px);
	}

	.rv-xy-plot__grid-lines line {
		opacity: 0.25;
	}

	.rv-xy-plot__axis__tick line {
		display: none;
	}

	.rv-xy-plot__axis__line {
		display: none;
	}
`

// prettier-ignore
interface Props {
  accepted: any;
  submitted: any;
  won: any;
  rawData: Array<{name: any, value: any}>
}

const Stats: React.FC<Props> = ({ accepted, submitted, won, rawData }) => {
	const sorted = rawData.sort((a, b) =>
		Number(a.value) < Number(b.value) ? 1 : -1
	)

	const data = sorted.map(item => {
		return { x: item.name, y: item.value }
	})

	return (
		<Container>
			<Content>
				<Title>Your top GCs are:</Title>
				<ChartContainer>
					<FlexibleChart height={300} xType="ordinal">
						<HorizontalGridLines />
						{gradient}
						<VerticalBarSeries data={data} color={'url(#chill)'} />
						<LabelSeries
							data={data}
							getLabel={d => d.y}
							labelAnchorX="middle"
							style={{
								fontSize: 38,
								fontWeight: 'bold',
								fill: 'white',
							}}
						/>
						<ChartLabel
							text="BID INVITES"
							includeMargin
							xPercent={0.02}
							yPercent={0.29}
						/>
					</FlexibleChart>
					<BottomLabelContainer>
						{sorted.map(({ name }) => (
							<Label>{name}</Label>
						))}
					</BottomLabelContainer>
				</ChartContainer>

				<LowerHalf>
					<Title>Out of the invites you received, your team:</Title>
					<WideBar />
					<Spacer />
					<StatsContainer>
						<Item>
							<AcceptedSVGWrapper>
								<BidAccepted />
							</AcceptedSVGWrapper>
							<Text>
								Accepted
								<GradientText>{Number(accepted).toLocaleString()}</GradientText>
							</Text>
						</Item>
						<Divider />
						<Item>
							<NotesSVGWrapper>
								<Notes />
							</NotesSVGWrapper>
							<Text>
								Submitted
								<GradientText>
									{Number(submitted).toLocaleString()}
								</GradientText>
							</Text>
						</Item>
						<Divider />
						<Item>
							<TrophySVGWrapper>
								<Trophy />
							</TrophySVGWrapper>
							<Text>
								Won
								<GradientText>{Number(won).toLocaleString()}</GradientText>
							</Text>
						</Item>
					</StatsContainer>
				</LowerHalf>
			</Content>
		</Container>
	)
}
export default Stats
