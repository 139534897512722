import * as React from 'react'
import { cyan, navy, grayText } from 'shared/colors'
import styled from 'styled-components'
import { Button } from 'views/components'
import { laptop, tablet, mobile } from 'shared/media-queries'

const RootContainer = styled.div`
	height: 493px;
	margin-bottom: 234px;
	margin-right: 100px;
	margin-left: 80px;
	box-shadow: 0 10px 28px 0 rgba(0, 0, 0, 0.12);
	${tablet} {
		margin-bottom: 350px;
		height: 380px;
	}
	${mobile} {
		margin-right: -120px;
		margin-left: -132px;
		margin-bottom: 640px;
	}
`

const CardContainer = styled.div`
	height: 493px;
	width: 441px;
	background-color: white;
	justify-content: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	${laptop} {
		width: 383px;
	}
	${tablet} {
		height: 380px;
		width: 252px;
	}
`

const ImageContainer = styled.div`
	height: 266px;
	width: 100%;
	background-color: ${navy};
	${laptop} {
		height: 231px;
	}
	${tablet} {
		height: 152px;
	}
`

const Content = styled.div`
	height: 226px;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	${laptop} {
		height: 262px;
	}
	${tablet} {
		width: 228px;
	}
`

const Title = styled.div`
	font-size: 22px;
	font-weight: 500;
	color: ${grayText};
	text-align: center;
	margin-bottom: 12px;
	${mobile} {
		font-size: 18px;
	}
`

const Subtitle = styled.div`
	font-size: 18px;
	font-weight: 300;
	color: ${grayText};
	text-align: center;
	width: 251px;
	margin: 0 auto;
	margin-bottom: 18px;
	${tablet} {
		font-size: 18px;
		width: 215px;
	}
`

const StyledButton = styled(Button)`
	color: ${cyan};
	background-color: white;
	border-radius: 2px;
	height: 42px;
	width: 200px;
	margin: 0 auto;
	border: solid 2px ${cyan};
	line-height: unset;
	:hover {
		color: white;
		border-radius: 2px ${cyan};
		border: solid 2px ${cyan};
		background-color: ${cyan};
	}
	:active {
		color: ${cyan};
		border-radius: 2px;
		border: solid 2px #34b4ac; // should we add this color to the palette?
		background-color: #34b4ac;
	}
	:visited {
		color: ${cyan};
	}
`
// prettier-ignore
interface CardProps {
  image: React.ReactNode;
  title: React.ReactNode;
	subtitle: React.ReactNode;
	link: string;
}

const Card: React.FC<CardProps> = ({ image, title, subtitle, link }) => {
	return (
		<RootContainer>
			<CardContainer>
				<ImageContainer>{image}</ImageContainer>
				<Content>
					<Title>{title}</Title>
					<Subtitle>{subtitle}</Subtitle>
					<StyledButton
						label="Learn more"
						onClick={() => {
							window.open(`${link}`, '_blank')
						}}
					/>
				</Content>
			</CardContainer>
		</RootContainer>
	)
}
export default Card
