import * as React from 'react'
import { navy, grayText } from 'shared/colors'
import styled from 'styled-components'
import Card from './card'
import Timeline from './img/timeline'
import TimelineMobile from './img/timeline-mobile'

import * as Images from './img'
import { mobile, tablet } from 'shared/media-queries'

const Container = styled.div`
	height: auto;
	justify-content: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: url(${Timeline}) no-repeat top 275px center;
	${mobile} {
		background: url(${TimelineMobile}) no-repeat top 255px center;
	}
`

const Spacer = styled.div`
	height: 610px;
	${tablet} {
		height: 560px;
	}
	${mobile} {
		height: 575px;
	}
`

const CardContainer = styled.div`
	display: flex;
	justify-content: center;
`

const Left = styled.div`
	display: flex;
	flex-direction: column;
`

const Right = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 245px;
	${tablet} {
		margin-top: 190px;
	}
	${mobile} {
		margin-top: 75px;
	}
`

const Title = styled.div`
	font-size: 42px;
	font-weight: bold;
	color: ${navy};
	text-align: center;
	margin-bottom: 23px;
	margin-top: 111px;
	max-width: 900px;
	${tablet} {
		max-width: 636px;
		font-size: 36px;
	}
	${mobile} {
		margin-top: 50px;
		margin-bottom: 85px;
		font-size: 32px;
		width: 320px;
	}
`

const BottomTitle = styled.div`
	font-size: 38px;
	font-weight: bold;
	margin-top: -100px;
	${mobile} {
		width: 220px;
		text-align: center;
		margin-bottom: 30px;
		font-size: 32px;
		margin-top: -540px;
	}
`

const BottomText = styled.div`
	font-size: 24px;
	font-weight: 300;
	color: ${grayText};
	max-width: 853px;
	text-align: center;
	padding-bottom: 80px;
	${tablet} {
		max-width: 668px;
	}
	${mobile} {
		font-size: 18px;
		width: 318px;
	}
`

const StyledBr = styled.br`
	${tablet} {
		display: none;
	}
`

const Growth = () => {
	return (
		<Container>
			<Title>
				Bid Board Pro gets even better in 2019,
				<StyledBr /> so you can jumpstart 2020.{' '}
			</Title>
			<CardContainer>
				<Left>
					<Spacer />
					<Card
						title="Follow-up Dates"
						subtitle="Easily set up follow-up dates with your GCs."
						image={<Images.April />}
						link="https://www.buildingconnected.com/bid-board/manage-bids/"
					/>
					<Card
						title="PlanGrid Integration"
						subtitle="Push files straight from your bid board to PlanGrid."
						image={<Images.August />}
						link="https://www.buildingconnected.com/bc-plangrid/"
					/>
					<Card
						title="File Viewer"
						subtitle="Save time viewing files and deciding to bid."
						image={<Images.October />}
						link="https://offer.buildingconnected.com/bid-board-pro-file-viewer"
					/>
				</Left>
				<Right>
					<Card
						title="Team Commenting"
						subtitle="Keep everyone in your team on the same page."
						image={<Images.March />}
						link="https://www.buildingconnected.com/bid-board/manage-bids/"
					/>
					<Card
						title="Bid Viewed Notifications"
						subtitle="Get notified when a GC views your proposal."
						image={<Images.July />}
						link="https://www.buildingconnected.com/bid-board/manage-bids/"
					/>
					<Card
						title="Reports 2.0"
						subtitle="Save and share detailed reports that automatically update when new data comes in."
						image={<Images.September />}
						link="https://www.buildingconnected.com/bid-board/bid-analytics"
					/>
				</Right>
			</CardContainer>
			<BottomTitle>More to come in 2020...</BottomTitle>
			<BottomText>
				Now that BuildingConnected is officially an Autodesk company, get ready
				for all the awesome product enhancements and integrations coming up next
				year.
			</BottomText>
		</Container>
	)
}
export default Growth
