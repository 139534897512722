import styled from 'styled-components'
import Button from 'components/button'
import { cyan, lightBlue } from 'shared/colors'

const GradientStyledButton = styled(Button)`
	width: 290px;
	margin: 0 auto;
	background-image: linear-gradient(217deg, ${cyan}, ${lightBlue});
	color: white;
	font-size: 24px;
	font-weight: 500;
	height: 60px;
	:hover {
		background-image: linear-gradient(217deg, #46e7de, #44bef7);
	}
	:active {
		background-image: linear-gradient(217deg, ${cyan}, ${lightBlue});
	}
`

export default GradientStyledButton
