import * as React from 'react'
import Layout from 'components/layout'
import { GrayBackground } from 'views/components'
import qs from 'query-string'
import Congrats from './congrats/'
import InvitesAndClients from './invites-and-clients'
import Footer from './footer'
import OurAccomplishments from './our-accomplishments'
import Growth from './growth'
import Stats from './stats'
import isBrowser from 'shared/is-browser'
import HowToWin from './how-to-win'
import useScrollTo from 'hooks/use-scroll-to'

const YearInRewviewLP: React.FC = () => {
	const accomplishmentsRef = React.useRef(null)
	useScrollTo({
		keysToRefs: {
			accomplishments: accomplishmentsRef,
		},
		offset: -60,
		ignoreLastScrolledTo: true,
	})

	if (!isBrowser) return null
	const { search } = window.location
	const {
		bidInvitesReceived,
		clientCount,
		accepted,
		submitted,
		won,
		company1name,
		company1value,
		company2name,
		company2value,
		company3name,
		company3value,
	} = qs.parse(search)

	const rawData = [{ name: company1name, value: company1value }]

	if (company2name) rawData.push({ name: company2name, value: company2value })
	if (company3name) rawData.push({ name: company3name, value: company3value })

	const hasAllStats = () =>
		accepted && submitted && won && company1name && company1value

	return (
		<Layout
			title="Bid Board Pro - Year in review"
			description="See your 2019 BuildingConnected stats"
			actuallyHideNav
		>
			<Congrats />
			{bidInvitesReceived && clientCount && (
				<InvitesAndClients
					bidInvitesReceived={bidInvitesReceived}
					clientCount={clientCount}
					forwardedRef={accomplishmentsRef}
				/>
			)}
			{hasAllStats() && (
				<Stats
					accepted={accepted}
					submitted={submitted}
					won={won}
					rawData={rawData}
				/>
			)}
			<HowToWin />
			<GrayBackground>
				<Growth />
			</GrayBackground>
			<OurAccomplishments />
			<Footer />
		</Layout>
	)
}

export default YearInRewviewLP
