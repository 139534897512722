import React, { useState, useEffect } from 'react'
import { Transition } from 'react-transition-group'
import styled from 'styled-components'

export const desktopHeaderHeight = 60
export const mobileHeaderHeight = 50
export const transitionSpeed = '250ms'
export const transitionHiddenPos = '-100%'
export const transitionViewablePos = '0%'

// prettier-ignore
interface ContainerProps {
	state: boolean;
}

// prettier-ignore
interface FadeProps {
	timeout: number;
}

const Container = styled.div`
	transition: 1s;
	opacity: ${(props: ContainerProps) => (props.state ? 1 : 0)};
	transform: translateY(${(props: ContainerProps) => (props.state ? 0 : 42)}px);
`

const FadeIn: React.FC<FadeProps> = ({ children, timeout }) => {
	const [animate, setAnimate] = useState(false)

	useEffect(() => {
		const timer = setTimeout(() => {
			setAnimate(true)
		}, timeout)
		return () => clearTimeout(timer)
	}, [timeout])

	return (
		<Transition in={animate} timeout={0}>
			<Container state={animate}>{children}</Container>
		</Transition>
	)
}

export default FadeIn
