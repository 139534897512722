import * as React from 'react'
import { lightBlue, cyan, navy, grayText } from 'shared/colors'
import styled from 'styled-components'
import backgroundImageURI from './img/background'
import BidInviteImage from '../icons/open-mail.svg'
import BuildingImage from '../icons/buildings.svg'
import { laptop, tablet, mobile } from 'shared/media-queries'

const Container = styled.div`
	height: 703px;
	background: url(${backgroundImageURI}) no-repeat left;
	${laptop} {
		height: 600px;
		background: url(${backgroundImageURI}) no-repeat center left -170px;
	}
	${tablet} {
		height: 500px;
		background: url(${backgroundImageURI}) no-repeat center left -170px;
	}
	${mobile} {
		height: 535px;
		background: url(${backgroundImageURI}) no-repeat center left -300px;
	}
`

const Spacer = styled.div`
	width: 75px;
	${mobile} {
		height: 40px;
	}
`

const Content = styled.div`
	width: 800px;
	margin: 0 auto;
	height: 100%;
	justify-content: center;
	display: flex;
	flex-direction: column;
	${mobile} {
		width: 375px;
	}
`

const Title = styled.div`
	padding-bottom: 80px;
	font-size: 54px;
	font-weight: bold;
	color: ${navy};
	text-align: center;
	line-height: 1.02;
	${tablet} {
		font-size: 36px;
	}
`

const WordingContainer = styled.div`
	display: flex;
	justify-content: center;
	${mobile} {
		flex-direction: column;
		align-items: center;
	}
`

const Text = styled.div`
	margin-left: 30px;
	font-size: 24px;
	font-weight: 300;
	line-height: 1.33;
	color: ${grayText};
	${tablet} {
		font-size: 18px;
	}
`

const Item = styled.div`
	display: flex;
	align-items: center;
	${mobile} {
		width: 290px;
		marion: 0 auto;
	}
`

const GradientText = styled.div`
	font-size: 90px;
	font-weight: bold;
	line-height: 1;
	color: ${cyan};
	@supports (-webkit-background-clip: text) {
		background: linear-gradient(217deg, ${cyan}, ${lightBlue});
		-webkit-text-fill-color: transparent;
		-webkit-background-clip: text;
	}
	${tablet} {
		font-size: 68px;
	}
`

const BuildingSVGWrapper = styled.div`
	svg {
		width: 100px;
		height: 95px;
	}
	${tablet} {
		svg {
			width: 83px;
			height: 78px;
		}
	}
`

const BidInviteSVGWrapper = styled.div`
	svg {
		width: 104px;
		height: 124px;
	}
	${mobile} {
		svg {
			width: 82px;
			height: 96px;
		}
	}
`

// prettier-ignore
interface Props {
  bidInvitesReceived: any;
	clientCount: any;
	forwardedRef: any;
}

const InvitesAndClients: React.FC<Props> = ({
	bidInvitesReceived,
	clientCount,
	forwardedRef,
}) => {
	return (
		<Container ref={forwardedRef}>
			<Content>
				<Title>
					Check out what <br />
					you’ve accomplished with us.
				</Title>
				<WordingContainer>
					<Item>
						<BidInviteSVGWrapper>
							<BidInviteImage />
						</BidInviteSVGWrapper>
						<Text>
							<GradientText>
								{Number(bidInvitesReceived).toLocaleString()}
							</GradientText>
							Bid invites received
						</Text>
					</Item>
					<Spacer />
					<Item>
						<BuildingSVGWrapper>
							<BuildingImage />
						</BuildingSVGWrapper>
						<Text>
							<GradientText>{clientCount}</GradientText>
							Clients
						</Text>
					</Item>
				</WordingContainer>
			</Content>
		</Container>
	)
}
export default InvitesAndClients
