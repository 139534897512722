import * as React from 'react'
import { lightBlue, cyan, navy, grayText } from 'shared/colors'
import styled from 'styled-components'
import { showRequestDemoModal } from 'components/modal-manager'
import CloudImage from '../icons/cloud.svg'
import ClockImage from '../icons/clock.svg'
import StatsImage from '../icons/statistics.svg'
import { mobile, tablet, laptop } from 'shared/media-queries'
import GradientStyledButton from '../components/gradient-button'

const Container = styled.div`
	height: 778px;
	justify-content: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	${mobile} {
		height: auto;
	}
`

const Spacer = styled.div`
	width: 75px;
	${laptop} {
		width: 30px;
	}
`

const Title = styled.div`
	font-size: 54px;
	font-weight: bold;
	color: ${navy};
	text-align: center;
	margin-bottom: 32px;
	${tablet} {
		font-size: 36px;
	}
	${mobile} {
		margin-top: 45px;
		width: 300px;
		font-size: 32px;
		margin-bottom: 20px;
	}
`

const Subtitle = styled.div`
	padding-bottom: 80px;
	font-size: 24px;
	font-weight: 300;
	color: ${grayText};
	text-align: center;
	width: 848px;
	${tablet} {
		font-size: 22px;
		width: 550px;
	}
	${mobile} {
		font-size: 20px;
		width: 285px;
		padding-bottom: 40px;
	}
`

const WordingContainer = styled.div`
	display: flex;
	justify-content: center;
	padding-bottom: 80px;
	${laptop} {
		padding-bottom: 42px;
	}
	${tablet} {
		font-size: 22px;
		width: 650px;
	}
	${mobile} {
		flex-direction: column;
		padding-bottom: 15px;
	}
`

const Text = styled.div`
	font-size: 24px;
	font-weight: 300;
	line-height: 1.33;
	text-align: center;
	color: ${grayText};
	padding-top: 40px;
	width: 268px;
	${laptop} {
		width: 290px;
	}
	${tablet} {
		font-size: 18px;
		width: 200px;
		padding-top: 20px;
	}
	${mobile} {
		width: 292px;
	}
`

const Item = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 45px;
	${tablet} {
		padding-bottom: 0;
	}
	${mobile} {
		padding-bottom: 45px;
	}
`

const CloudSVGWrapper = styled.div`
	height: 104px;
	${tablet} {
		width: 82px;
		height: 104px;
	}
`

const ClockSVGWrapper = styled.div`
	height: 104px;
	${tablet} {
		width: 92px;
		height: 104px;
	}
	${mobile} {
		width: 92px;
		height: 94px;
	}
`
const StatsSVGWrapper = styled.div`
	height: 104px;
	${tablet} {
		width: 134px;
		height: 104px;
	}
	${mobile} {
		width: 134px;
		height: 94px;
	}
`

const StyledButtonWithPadding = styled(GradientStyledButton)`
	${mobile} {
		margin-bottom: 80px;
	}
`

const HowToWin = () => {
	return (
		<Container>
			<Title>How to win more bids in 2020.</Title>
			<Subtitle>
				Subcontractors use Bid Board Pro to increase their win rates by up to
				25%.
			</Subtitle>
			<WordingContainer>
				<Item>
					<CloudSVGWrapper>
						<CloudImage width="82" height="104" />
					</CloudSVGWrapper>
					<Text>
						Automatically centralize all your bid invites with an online bid
						board.
					</Text>
				</Item>
				<Spacer />
				<Item>
					<ClockSVGWrapper>
						<ClockImage width="92" height="94" />
					</ClockSVGWrapper>
					<Text>
						Eliminate manual entry, saving up to 8 hours per estimator each
						week.
					</Text>
				</Item>
				<Spacer />
				<Item>
					<StatsSVGWrapper>
						<StatsImage width="134" height="84" />
					</StatsSVGWrapper>
					<Text>
						Get real-time insights on your hit rates and team performance.
					</Text>
				</Item>
			</WordingContainer>
			<StyledButtonWithPadding
				onClick={() => showRequestDemoModal()}
				css={{
					'min-width': '290px',
					'background-image': `linear-gradient(217deg, ${cyan}, ${lightBlue})`,
					color: 'white',
					'font-size': '24px',
					'font-weight': '500',
					height: '60px',
				}}
			>
				Get a demo
			</StyledButtonWithPadding>
		</Container>
	)
}
export default HowToWin
