import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import GatsbyImage from 'gatsby-image'

const SeptemberImage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				fileName: file(
					relativePath: {
						eq: "bid-board/year-in-review/growth/img/september@3x.png"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1200) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	)

	return (
		<GatsbyImage fluid={data.fileName.childImageSharp.fluid} alt="September" />
	)
}

export default SeptemberImage
