import * as React from 'react'
import { navy } from 'shared/colors'
import styled from 'styled-components'
import { showRequestDemoModal } from 'components/modal-manager'
import GradientStyledButton from '../components/gradient-button'
import { mobile } from 'shared/media-queries'

const Container = styled.div`
	height: 500px;
	justify-content: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	${mobile} {
		height: 300px;
	}
`

const Title = styled.div`
	font-size: 54px;
	font-weight: bold;
	color: ${navy};
	text-align: center;
	margin-bottom: 55px;
	line-height: 1.02;
	${mobile} {
		font-size: 32px;
		max-width: 420px;
	}
`

const Footer = () => {
	return (
		<Container>
			<Title>
				Get ready to win more bids <br />
				in 2020 with Bid Board Pro.
			</Title>
			<GradientStyledButton onClick={() => showRequestDemoModal()}>
				Get a demo
			</GradientStyledButton>
		</Container>
	)
}
export default Footer
