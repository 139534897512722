import * as React from 'react'
import { navy } from 'shared/colors'
import styled from 'styled-components'
import Logo from '../icons/bc-logo.svg'
import backgroundImageURI from './background'
import FadeIn from './animation'
import { laptop, mobile } from 'shared/media-queries'
import { Link } from 'gatsby'
import Lines from './lines'
import qs from 'query-string'
import GradientStyledButton from '../components/gradient-button'

const Container = styled.div`
	height: 960px;
	justify-content: center;
	background: url(${backgroundImageURI}) no-repeat center;
	background-color: ${navy};
	${laptop} {
		height: 768px;
	}
	${mobile} {
		height: 695px;
	}
`

const Spacer = styled.div`
	height: 250px;
	${laptop} {
		height: 200px;
	}
	${mobile} {
		height: 100px;
	}
`

const Content = styled.div`
	margin: 0 auto;
	position: relative;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 111px;
	${laptop} {
		padding-top: 70px;
	}
	${mobile} {
		padding-top: 42px;
	}
`
const LogoContainer = styled.div`
	justify-content: center;
	margin: 0 auto;
	position: relative;
	width: 260px;
	padding-top: 50px;
`

const Title = styled.div`
	font-size: 24px;
	font-weight: bold;
	color: white;
	text-align: center;
	letter-spacing: 3px;
	${mobile} {
		font-size: 16px;
	}
`

const Year = styled.div`
	font-size: 108px;
	font-weight: bold;
	text-align: center;
	color: white;
	@supports (-webkit-background-clip: text) {
		background-image: linear-gradient(90deg, #eeeeee, #d8d8d8);
		-webkit-background-clip: text;
		background-clip: text;
		text-fill-color: transparent;
		-webkit-text-fill-color: transparent;
	}
`

const WordingContainer = styled.div`
	display: flex;
	justify-content: center;
`

const Text = styled.div`
	font-size: 24px;
	font-weight: 300;
	color: white;
	text-align: center;
	max-width: 566px;
	margin: 0 auto;
	margin-bottom: 50px;
	${mobile} {
		font-size: 18px;
		max-width: 80%;
	}
`

const LinesContainer = styled.div`
	height: 960px;
	position: absolute;
	overflow: hidden;
	width: 100%;
	margin: 0 auto;
	${laptop} {
		height: 768px;
		padding-top: 75px;
	}
	${mobile} {
		padding-top: 0;
		height: 695px;
	}
`

const Congrats: React.FC = () => {
	const { search, pathname } = window.location
	const { scrollTo } = qs.parse(search)
	const getSmartUrl = () => {
		if (scrollTo) return `${pathname}${search}`
		return `${pathname}${search}&scrollTo=accomplishments`
	}

	return (
		<Container>
			<FadeIn timeout={300}>
				<LinesContainer>
					<Lines />
				</LinesContainer>
			</FadeIn>
			<LogoContainer>
				<Logo />
			</LogoContainer>
			<Content>
				<FadeIn timeout={500}>
					<Title>CONGRATULATIONS ON A GREAT</Title>
					<Year>2019</Year>
				</FadeIn>
				<Spacer />
				<FadeIn timeout={800}>
					<WordingContainer>
						<Text>
							See what you did this year, and see how you can win even more bids
							in 2020 with Bid Board Pro.
						</Text>
					</WordingContainer>
				</FadeIn>
				<FadeIn timeout={1000}>
					<Link to={getSmartUrl()}>
						<GradientStyledButton>See your stats</GradientStyledButton>
					</Link>
				</FadeIn>
			</Content>
		</Container>
	)
}
export default Congrats
