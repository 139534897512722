import * as React from 'react'
import { lightBlue, cyan, navy } from 'shared/colors'
import styled from 'styled-components'
import { tablet, mobile, laptop } from 'shared/media-queries'
import BidInviteImage from '../icons/open-mail-white.svg'
import BuildingImage from '../icons/buildings-white.svg'
import HardHatImage from '../icons/hardhat.svg'
import MasonImage from '../icons/masonry.svg'
import backgroundImageURI from './background'

const Container = styled.div`
	height: 635px;
	justify-content: center;
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	background: url(${backgroundImageURI}) no-repeat top center;
	background-blend-mode: soft-light;
	background-color: ${navy};
	${tablet} {
		height: 540px;
		background: url(${backgroundImageURI}) no-repeat top -220px center;
		background-blend-mode: soft-light;
		background-color: ${navy};
	}
	${mobile} {
		height: 765px;
	}
`

const Content = styled.div`
	height: 100%;
	justify-content: center;
	display: flex;
	flex-direction: column;
`

const WordingContainer = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	${mobile} {
		align-items: center;
	}
`

const Row = styled.div`
	display: flex;
	justify-content: center;
	${mobile} {
		flex-direction: column;
		align-items: flex-start;
	}
`

const Item = styled.div`
	display: flex;
	align-items: center;
	width: 404px;
	${tablet} {
		width: 308px;
	}
`

const Title = styled.div`
	font-size: 42px;
	font-weight: bold;
	color: white;
	text-align: center;
	margin-bottom: 78px;
	line-height: 1.11;
	${tablet} {
		width: 575px;
		margin: 0 auto;
		margin-bottom: 50px;
		font-size: 38px;
	}
	${mobile} {
		width: 350px;
		font-size: 32px;
		margin-bottom: 50px;
	}
`

const Text = styled.div`
	margin-left: 30px;
	font-size: 22px;
	font-weight: 300;
	line-height: 1.33;
	color: white;
	${tablet} {
		font-size: 18px;
	}
`

const GradientText = styled.div`
	color: ${cyan};
	@supports (-webkit-background-clip: text) {
		background: linear-gradient(217deg, ${cyan}, ${lightBlue});
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
	font-size: 54px;
	font-weight: bold;
	line-height: 1;
	${tablet} {
		font-size: 38px;
	}
`

const Spacer = styled.div`
	width: 155px;
	height: 75px;
	${laptop} {
		width: 75px;
	}
	${tablet} {
		width: 64px;
		height: 34px;
	}
`

const BuildingSVGWrapper = styled.div`
	svg {
		width: 92px;
		height: 88px;
	}
	${tablet} {
		svg {
			width: 75px;
			height: 72px;
		}
	}
	${mobile} {
		height: 96px;
		width: 96px;
	}
`
const HardhatSVGWrapper = styled.div`
	svg {
		width: 103px;
		height: 72px;
	}
	${tablet} {
		svg {
			width: 81px;
			height: 57px;
		}
	}
	${mobile} {
		height: 96px;
		width: 96px;
		padding-top: 18px;
		padding-left: 5px;
	}
`
const BidInviteSVGWrapper = styled.div`
	svg {
		width: 96px;
		height: 109px;
	}
	${tablet} {
		svg {
			width: 83px;
			height: 94px;
		}
	}
	${mobile} {
		height: 96px;
		width: 96px;
	}
`
const MasonSVGWrapper = styled.div`
	svg {
		width: 92x;
		height: 91px;
	}
	${tablet} {
		svg {
			width: 76px;
			height: 75px;
		}
	}
	${mobile} {
		height: 96px;
		width: 96px;
		svg {
			height: 81px;
			width: 90px;
		}
	}
`

const OurAccomplishments: React.FC = () => {
	return (
		<Container>
			<Content>
				<Title>See what we’ve accomplished in 2019 with you.</Title>
				<WordingContainer>
					<Row>
						<Item>
							<BuildingSVGWrapper>
								<BuildingImage />
							</BuildingSVGWrapper>
							<Text>
								<GradientText>119,001</GradientText>
								Total projects bid out
							</Text>
						</Item>

						<Spacer />
						<Item>
							<BidInviteSVGWrapper>
								<BidInviteImage />
							</BidInviteSVGWrapper>
							<Text>
								<GradientText>49,794,090</GradientText>
								Total bid invites sent out
							</Text>
						</Item>
					</Row>
					<Spacer />
					<Row>
						<Item>
							<HardhatSVGWrapper>
								<HardHatImage />
							</HardhatSVGWrapper>
							<Text>
								<GradientText>1,427</GradientText>
								New GCs joined
							</Text>
						</Item>

						<Spacer />
						<Item>
							<MasonSVGWrapper>
								<MasonImage />
							</MasonSVGWrapper>
							<Text>
								<GradientText>88,584</GradientText>
								New subs joined
							</Text>
						</Item>
					</Row>
				</WordingContainer>
			</Content>
		</Container>
	)
}

export default OurAccomplishments
